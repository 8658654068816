import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/api/contacts';

class ContactsService {
  addContact(contact) {
    return axios.post(API_URL , contact, { headers: authHeader() });
  }

  deleteContact(id) {
    return axios.delete(API_URL +"/"+ id, { headers: authHeader() } );
  }

  updateContact(id,contact) {
    return axios.patch(API_URL +"/"+ id,contact, { headers: authHeader() });
  }


}

export default new ContactsService();