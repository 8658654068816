<template>
    <div>
        <form v-on:submit.prevent="save">
        <img v-if="imagePreview" :src="imagePreview" alt="">
        <img v-if="!imagePreview && employee.foto" :src="base_api_url+'/images/'+employee.foto" alt="" style="width:150px; height:150px;">
        <div class="mb-3">
            <label for="duration" class="form-label">Immagine profilo:</label>
            <input type="file" class="form-control" @change="onFileChange" rows="10">
        </div>
        <div class="mb-3">
            <label for="country" class="form-label">Nome:</label>
            <input type="text" class="form-control" v-model="employee.nome" required>
        </div>
        <div class="mb-3">
            <label for="passport number" class="form-label">Cognome:</label>
            <input type="text" class="form-control" v-model="employee.cognome" required>
        </div>
        <div class="mb-3">
            <label for="country" class="form-label">Matricola:</label>
            <input type="text" class="form-control" v-model="employee.matricola" >
        </div>
        <div class="mb-3">
            <label for="duration" class="form-label">Ruolo:</label>
            <input type="text" class="form-control" v-model="employee.ruolo">
        </div>
        <div class="mb-3">
            <label for="duration" class="form-label">E-mail:</label>
            <input type="text" class="form-control" v-model="employee.email">
        </div>
        <div class="mb-3">
            <label for="duration" class="form-label">Anno di riferimento:</label>
            <input type="text" class="form-control" v-model="employee.anno_corrente">
        </div>
        <div class="mb-3">
            <label for="duration" class="form-label">Presentazione IT:</label>
            <textarea class="form-control" v-model="employee.presentazione_it" rows="10"></textarea>
        </div>
        <div class="mb-3">
            <label for="duration" class="form-label">Presentazione EN:</label>
            <textarea class="form-control" v-model="employee.presentazione_en" rows="10"></textarea>
        </div>
        <div class="mb-3">
            <label for="duration" class="form-label">Curriculum vitae:</label>
            <a v-if="employee.allegato" :href="base_api_url+'/documents/'+employee.allegato">Curriculum vitae attuale</a>
            <input type="file" class="form-control" @change="onFileChange" rows="10">
        </div>
            <div class="contacts p-2">
            <h4 class="me-2 mt-2 contacts-title">Contatti</h4>
            <button class="btn btn-success btn-circle btn-add-contact" @click="addContact"><font-awesome-icon icon="plus"/></button>
                <div class="previous"
                v-for="(contact, counter) in employee.contacts"
                v-bind:key="counter">
                    <div class="mb-3">
                    <button class="btn btn-danger btn-circle" @click="deleteContact(counter,contact)"><font-awesome-icon icon="times"/></button>
                        <label for="duration" class="m-1" >Etichetta Italiano:</label>
                        <input type="text" class="m-3" v-model.lazy="contact.etichetta_it" required>
                        <label for="duration" class="m-1">Etichetta Inglese:</label>
                        <input type="text" class="m-3" v-model.lazy="contact.etichetta_en" required> 
                        <label for="duration" class="m-1">Informazione:</label>
                        <input type="text" class="m-3" v-model.lazy="contact.info" required>
                    </div>
                </div>
            </div>
            <div class="contacts p-2 mt-3">
            <h4 class="me-2 mt-2 contacts-title">Links</h4>
            <button class="btn btn-success btn-circle btn-add-contact" @click="addLink"><font-awesome-icon icon="plus"/></button>
                <div class="previous"
                v-for="(link, counter_l) in employee.links"
                v-bind:key="counter_l">
                    <div class="mb-3">
                    <button class="btn btn-danger btn-circle" @click="deleteLink(counter_l,link)"><font-awesome-icon icon="times"/></button>
                        <label for="duration" class="m-1" >Etichetta:</label>
                        <input type="text" class="m-3" v-model.lazy="link.etichetta" required>
                        <label for="duration" class="m-1">Link:</label>
                        <input type="text" class="m-3" v-model.lazy="link.link" required>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary mt-3">Salva</button>
        </form>
        <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
        </div>
    </div>
</template>

<script>
import { computed,ref,onMounted,inject } from "vue";
import { useRouter,useRoute,onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import eServices from "../services/employees.service";
import cServices from "../services/contacts.service";
import lServices from "../services/links.service";

export default {
name:"edit",
    setup(){
    
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const employee = ref({});
        const contacts = computed(() => {return employee.value.contacts;});
        const links = computed(() => {return employee.value.links;});
        const message = ref("");
        const imageFile = ref({});
        const pdfFile = ref({});
        const imagePreview = ref("");
        const base_api_url = inject('api_url');

        const id = computed(() => {
            let id;

            if (localStorage.getItem("employeeID") === null) {

                id = route.params.id
                localStorage.setItem("employeeID",id);

            }else{

                id = localStorage.getItem("employeeID");

            }
        
            return id;
        });

        const loggedIn = computed(() => {return store.state.auth.status.loggedIn;});


        if(!loggedIn.value){
            router.push({ name: "home" });
        }

        const LoadEmployee = () =>{
            eServices.getEmployee(id.value).then(
                (response) => {
                    employee.value = response.data;
                },
                (error) => {
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
            );
        }

        onMounted(LoadEmployee);

        const addContact = () =>{
        employee.value.contacts.push({
                etichetta_it:'',
                etichetta_en:'',
                info:''
            })
        }

        const deleteContact = (counter, contact) =>{
            console.log('Numero: '+ counter + ' data: ' + contact.info);
            if(contact.id){
                cServices.deleteContact(contact.id);
                console.log('Cancellato: '+ contact.id)
            }
            employee.value.contacts.splice(counter,1);
        }

        const addLink = () =>{
        employee.value.links.push({
                etichetta:'',
                link:''
            })
        }

        const deleteLink = (counter, link) =>{
            console.log('Numero: '+ counter + ' data: ' + link.etichetta);
            if(link.id){
                lServices.deleteLink(link.id);
                console.log('Cancellato: '+ link.id)
            }
            employee.value.links.splice(counter,1);
        }

        const save = () => {

            let links = employee.value.links;
            let contacts = employee.value.contacts
            let linksList = [];
            let newLinks = {}; 
            let contactsList = [];
            let newContacts = {};
            let prm = [];
            
            //elimino temporaneamente le proprietà che non mi servono da employee
            delete employee.value.links
            delete employee.value.contacts
            delete employee.value.teachings

            if(!employee.value.allegato){
                delete employee.value.allegato
            }


            if(!employee.value.foto){
                delete employee.value.foto
            }

            //aggiorno employee
            const upem = eServices.updateEmployee(employee.value.id,employee.value).then(
                () => {
                
                },
                (error) => {
                message.value =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
            ); 
            
            prm.push(upem);

            //Reinserisco le proprietà che ho tolto in precedenza
            employee.value.links = links;
            employee.value.contacts = contacts;

            //Loop per verificare se i contatti sono da aggiornare o da inserire

            employee.value.contacts.forEach(element => {
                //Se l'elemento ha un id significa che va aggiornato altrimenti è nuovo e va aggiunto
                if(element.id){
                    const upct = cServices.updateContact(element.id,element).then(
                        () => {
                        
                        },
                        (error) => {
                        message.value =
                            (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                        }
                    ); 

                    prm.push(upct);

                }else{
                    //se il contatto è da inserire lo aggiungo alla lista
                    contactsList.push(element);
                }
            });

            //verifico che ci siano nuovi links da inserire
            if(contactsList.length > 0){
            
                //costruisco il nuovo oggetto per salvare i nuovi contatti
                newContacts.employee_id = employee.value.id;
                newContacts.contacts = contactsList;

                //salvo i nuovi contatti
                const addct = cServices.addContact(newContacts).then(
                    () => {
                    
                    },
                    (error) => {
                    message.value =
                        (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                ); 

                prm.push(addct);

            }

            //Loop per verificare se i links sono da aggiornare o da inserire

            employee.value.links.forEach(element => {
                //Se l'elemento ha un id significa che va aggiornato altrimenti è nuovo e va aggiunto
                if(element.id){
                    const uplnk = lServices.updateLink(element.id,element).then(
                        () => {
                        
                        },
                        (error) => {
                        message.value =
                            (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                        }
                    ); 

                    prm.push(uplnk);

                }else{
                    //se il link è da inserire lo aggiungo alla lista
                    linksList.push(element);
                }
            });

            //verifico che ci siano nuovi links da inserire
            if(linksList.length > 0){
                //costruisco il nuovo oggetto per salvare i nuovi links
                newLinks.employee_id = employee.value.id;
                newLinks.links = linksList;

                //salvo i nuovi links
                 const addlnk = lServices.addLink(newLinks).then(
                    () => {
                    
                    },
                    (error) => {
                    message.value =
                        (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                );

                prm.push(addlnk);

            }

            //Carico i files
            
            let formData = new FormData();
            formData.append('fileImg', imageFile.value);
            formData.append('fileUp', pdfFile.value);

            const flup = eServices.fileUpload(formData).then(
                () => {
                
                },
                (error) => {
                message.value =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
            );

            prm.push(flup);

            Promise.all(prm)
            .then(() => {
                router.push({ name: "home" });
            })

        }


        onBeforeRouteLeave(()=>{

            window.localStorage.removeItem('employeeID');

        });

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            
            if(files[0].name.split('.').pop() == "pdf"){

                pdfFile.value = files[0];

                employee.value.allegato = files[0].name

            }else{

                imageFile.value = files[0];

                createImage(imageFile.value);

                

            }
        }

        const createImage = (file) => {
            var reader = new FileReader();

            reader.onload = (e) => {
                imagePreview.value = e.target.result;
                employee.value.foto = file.name
            };
            reader.readAsDataURL(file);
        }
        const removeImage =  () => {
            imagePreview.value = '';
        }

        return{
            loggedIn,
            id,
            employee,
            contacts,
            links,
            message,
            imageFile,
            pdfFile,
            imagePreview,
            base_api_url,
            addContact,
            deleteContact,
            addLink,
            deleteLink,
            save,
            onFileChange,
            removeImage,
            createImage
        }
    }
}
</script>

<style scoped>
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  margin-right: 15px;
  cursor: pointer;
}
.contacts-title{
    display: inline-flex;
}
.btn-add-contact{
    margin-top: -6px;
}
.contacts{
    border: 1px solid;
}
</style>