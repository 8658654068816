import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/api/links';

class LinksService {
  addLink(link) {
    return axios.post(API_URL , link, { headers: authHeader() });
  }

  deleteLink(id) {
    return axios.delete(API_URL +"/"+ id, { headers: authHeader() } );
  }

  updateLink(id,link) {
    return axios.patch(API_URL + "/"+ id,link, { headers: authHeader() });
  }

}

export default new LinksService();